import React from 'react';
import './Banner.css';

export default function Banner({children, title}) {
    return (
        <div className="banner">
            <h1>{title}</h1>
            {children}
        </div>
    )
}
