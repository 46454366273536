import React from 'react';
import NavDesktop from '../NavDesktop';
import Navbar from 'react-bootstrap/Navbar';
import Image from 'react-bootstrap/Image';
import { Link } from 'react-router-dom';


import DrawerToggleButton from'../SideDrawer/DrawerToggleButton';
import './Toolbar.css';

const toolbar = props => (
    <header className="toolbar">
        <nav className='toolbar_nav'>
        <Navbar.Brand className="mr-sm-4 mobileLogo" as={Link} to="./"><Image className="img-fluid" srcSet="./assest/logo-sm.png 250w, 
                                ./assest/logo-md.png 350w, 
                                ./assest/logo-business.png 456w" 
                                sizes="(max-width: 580px) 250px,
                                       (max-width: 780px) 350px,
                                        456px"
                                src="./assest/logo-business.png" alt="Sun West Mortgage Company, Inc. Logo" /></Navbar.Brand>
            <div className="toolbar_toggle-button">

                <DrawerToggleButton click={props.drawerClickHandler} />
            </div>
           <NavDesktop setModalShow={props.setModalShow} />
        </nav>
    </header>
);

export default toolbar;