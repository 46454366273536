import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import StyledHero from '../components/StyledHero'
import DocuImg from '../images/documentsneeded.jpg';
import Banner from '../components/Banner';
import {Helmet} from "react-helmet";



export default function Documents() {

    return (
        <div id="documentsWrappers">
            <Helmet>
                <title>Documents Needed</title>
                <meta name="description" content="LowRates US, Sun West documents needed." />
            </Helmet>
            <StyledHero img={DocuImg}>
                <Banner title="Documents Needed">
                </Banner>
            </StyledHero>            
            <Container>
                <Row>
                    <Col lg={12}>                                                                    
                        <div className="table-1 p-2 px-sm-0 pt-5">
                            <Table striped bordered responsive="md">
                                <thead className="align-middle" style={{backgroundColor: "#78b1e2"}}>
                                    <tr className="centered">
                                        <th>Documents</th>
                                        <th>Purchase</th>
                                        <th>Refinance</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="tablebg">
                                        <td align="left" colSpan="3"><b>Identification Documents</b></td>
                                    </tr>

                                    <tr className="bg-white">
                                        <td align="left">Driver&#39;s License</td>
                                        <td className="align-middle" align="center" rowSpan="2"><b>Yes</b></td>
                                        <td className="align-middle" align="center" rowSpan="2"><b>Yes</b></td>
                                    </tr>
                                    <tr className="bg-white">
                                        <td align="left">Social Security Card</td>
                                    </tr>
                                    <tr className="tablebg">
                                        <td align="left" colSpan="3"><b>Income Documents</b></td>
                                    </tr>
                                    <tr>
                                        <td align="left" colSpan="3"><b><em>Salaried</em></b></td>
                                    </tr>
                                    <tr className="bg-white">
                                        <td align="left">Pay Stubs for 2 recent pay periods</td>
                                        <td className="align-middle" align="center" rowSpan="3"><b>Yes</b></td>
                                        <td className="align-middle" align="center" rowSpan="3"><b>Yes*</b></td>
                                    </tr>
                                    <tr className="bg-white">
                                        <td align="left">Last two years - W2 forms, 1099&#39;s</td>
                                    </tr>
                                    <tr className="bg-white">
                                        <td align="left">Last two years - 1040 (Personal Federal Tax Returns) with all supporting schedules.</td>
                                    </tr>

                                    <tr>
                                        <td align="left" colSpan="3"><b><em>Self Employed</em></b></td>
                                    </tr>
                                    <tr className="bg-white">
                                        <td align="left">Current year's Year to date Profit and Loss Statement to current month</td>
                                        <td className="align-middle" align="center" rowSpan="3"><b>Yes</b></td>
                                        <td className="align-middle" align="center" rowSpan="3"><b>Yes*</b></td>
                                    </tr>
                                    <tr className="bg-white">
                                        <td align="left">Last two years - Form 1120S / Form 1065 / Form 1120 Federal Corporate tax returns only with all supporting schedules</td>
                                    </tr>
                                    <tr className="bg-white">
                                        <td align="left">Last two years – K-1&#39;s with all supporting schedules</td>
                                    </tr>

                                    <tr>
                                        <td align="left" colSpan="3"><b><em>Other Income</em></b></td>
                                    </tr>
                                    <tr>
                                        <td align="left">Retirement Benefit Award letters with proof of 3 years continuance</td>
                                        <td className="align-middle" align="center" rowSpan="4"><b>Yes</b></td>
                                        <td className="align-middle" align="center" rowSpan="4"><b>Yes*</b></td>
                                    </tr>
                                    <tr>
                                        <td align="left">Social Security Award Letter</td>
                                    </tr>
                                    <tr className="bg-white">
                                        <td align="left">Disability Award Letter</td>
                                    </tr>
                                    <tr>
                                        <td align="left">Divorce Decree, Child Support Order &amp; DES received payment history</td>
                                    </tr>
                                    <tr className="tablebg">
                                        <td align="left" colSpan="3"><b>Asset Documents</b></td>
                                    </tr>
                                    <tr>
                                        <td align="left">Checking / Saving Bank Account Statements: Bank statements for recent two consecutive months</td>
                                        <td className="align-middle" align="center"><b>Yes</b></td>
                                        <td className="align-middle" align="center"><b>Yes*</b></td>
                                    </tr>
                                    <tr>
                                        <td>Gift Funds: <br />
                                            <ul>
                                                <li>Completely filled Gift letter signed by the donor.</li>
                                                <li>Donor's bank statement</li>
                                            </ul>
                                        </td>
                                        <td className="align-middle" align="center"><b>Yes</b></td>
                                        <td className="align-middle" align="center"><b>No</b></td>
                                    </tr>
                                    <tr>
                                        <td>Retirement Accounts / Stocks and Bonds<br />
                                            <ul>
                                                <li>Recent statement covering minimum of 60 days</li>
                                                <li>Terms and Conditions of Withdrawal confirming sufficient access to withdraw funds whenever required</li>
                                            </ul>
                                        </td>
                                        <td className="align-middle" align="center"><b>Yes</b></td>
                                        <td className="align-middle" align="center"><b>Yes*</b></td>
                                    </tr>
                                    <tr className="tablebg">
                                        <td align="left" colSpan="3"><b>Transaction Specific Documents</b></td>
                                    </tr>
                                    <tr>
                                        <td align="left" colSpan="3"><b><em>Purchase</em></b></td>
                                    </tr>
                                    <tr>
                                        <td>Purchase / Sales Contract or Offer to Purchase with all Addenda (Attachments and Enclosures) </td>
                                        <td className="align-middle" align="center" rowSpan="2"><b>Yes</b></td>
                                        <td className="align-middle" align="center" rowSpan="2"><b>No</b></td>
                                    </tr>
                                    <tr>
                                        <td>Copy of the Earnest Money Check and Receipt from the Title / Escrow company
                                        </td>
                                    </tr>
                                    <tr>
                                        <td align="left">Mortgage statements for mortgages associated with other properties owned</td>
                                        <td className="align-middle" align="center"><b>Yes</b></td>
                                        <td className="align-middle" align="center"><b>Yes*</b></td>
                                    </tr>
                                    <tr>
                                        <td align="left" colSpan="3"><b><em>Refinance</em></b></td>
                                    </tr>
                                    <tr>
                                        <td align="left">Mortgage statements for mortgages associated with other properties owned</td>
                                        <td className="align-middle" align="center"><b>Yes</b></td>
                                        <td className="align-middle" align="center"><b>Yes*</b></td>
                                    </tr>
                                    <tr>
                                        <td align="left">Current Mortgage Statement(s)</td>
                                        <td className="align-middle" align="center" rowSpan="4"><b>No</b></td>
                                        <td className="align-middle" align="center" rowSpan="4"><b>Yes*</b></td>
                                    </tr>
                                    <tr>
                                        <td align="left">Homeowner&#39;s Insurance Declaration page reflecting annual premium</td>
                                    </tr>
                                    <tr className="bg-white">
                                        <td align="left">Tax Bill / Tax Certificate</td>
                                    </tr>
                                    <tr>
                                        <td align="left">Current Homeowners&#39; Association (HOA) bill</td>
                                    </tr>
                                </tbody>
                            </Table>
                            <small>*Not required, in case of a streamline refinance program.</small>
                        </div>
                    </Col>
                </Row>
            </Container>              
        </div>
    )
}
