import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Collapse from "react-bootstrap/Collapse";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import StyledHero from "../components/StyledHero";
import FhaImg from "../images/fha-header.jpg";
import Banner from "../components/Banner";
import { Link } from "react-router-dom";
import { FiCheckCircle } from "react-icons/fi";
import { MdLabelOutline } from "react-icons/md";
import { MdLocalPhone } from "react-icons/md";
import { GoLinkExternal } from "react-icons/go";
import styled from "styled-components";
import { Helmet } from "react-helmet";

const ArchonButton = styled.button`
  background: transparent;
  color: #f5911e;
  font-size: 1em;
  padding-bottom: 10px;
  border: none;

  &:hover {
    opacity: 0.8;
  }
`;

export default function FHA(props) {
  const [open, setOpen] = useState(false);

  return (
    <div id="fhaWrapper">
      <Helmet>
        <title>FHA Loans</title>
        <meta
          name="description"
          content="FHA loan programs are established to make it easier to obtain a mortgage for people who would normally find it difficult to qualify for a mortgage due to their income level, credit history, or their ability to put down a sufficient amount of down payment."
        />
      </Helmet>
      <StyledHero img={FhaImg}>
        <Banner title="FHA Mortgage"></Banner>
      </StyledHero>
      <Container>
        <div className="wrapper-content bg-white rounded box-shadow">
          <div className="pinside40 rounded">
            <div className="product-tabs">
              <Row>
                <Col lg={12} className="nopadding">
                  <div className="tab-content">
                    <div
                      role="tabpanel"
                      className="tab-pane active"
                      id="overview"
                    >
                      <h2>About FHA Mortgage</h2>
                      <p>
                        FHA loan programs are established to make it easier to
                        obtain a mortgage for people who would normally find it
                        difficult to qualify for a mortgage due to their income
                        level, credit history, or their ability to put down a
                        sufficient amount of down payment. If you are a
                        first-time home buyer or someone who is looking to
                        refinance from a conventional mortgage, then this may be
                        the perfect option for you. Sun West Mortgage Company,
                        Inc. offers a complete line of loan products insured by
                        the federal government&rsquo;s Federal Housing
                        Administration (FHA) including but not limited to FHA
                        203(b), FHA 203(k), FHA Manufactured Housing, FHA
                        Section 184 Indian Home Loan, and FHA Back-to-Work.
                      </p>
                      <h3>FHA Mortgage Benefits and Highlights</h3>
                      <ul className="listnone bullet">
                        <li>
                          <FiCheckCircle className="bullet-check-circle-default" />{" "}
                          Lower down payment requirements (as little as 3.5% on
                          most products), with gifts funds eligible for use
                          towards your down payment.
                        </li>
                        <li>
                          <FiCheckCircle className="bullet-check-circle-default" />{" "}
                          Lower credit scores requirements. Sun West Mortgage
                          Company, Inc. is able to finance your FHA loan with
                          less-than-perfect credit scores and a FICO as low as
                          500.
                        </li>
                        <li>
                          <FiCheckCircle className="bullet-check-circle-default" />{" "}
                          A higher Debt-to-Income (DTI) ratio is acceptable.
                        </li>
                        <li>
                          <FiCheckCircle className="bullet-check-circle-default" />{" "}
                          Using an FHA 203k loan, upgrade, improve, or
                          rehabilitate existing and new homes.
                        </li>
                        <li>
                          <FiCheckCircle className="bullet-check-circle-default" />{" "}
                          In some cases, you can use an FHA loan to refinance to
                          avoid facing foreclosure if you are experiencing
                          financial hardship.
                        </li>
                      </ul>
                      <br />
                      <h2>FHA 203B</h2>
                      <p>
                        The 203B loan is the FHA standard single-family home
                        loan for the purchase or to refinance a principal
                        residence. The mortgage loan is funded by a lending
                        institution, such as a mortgage company, bank, savings
                        and loan association and HUD insures the mortgage.
                      </p>

                      <ArchonButton
                        onClick={() => setOpen(!open)}
                        aria-controls="collapse-fade-text"
                        aria-expanded={open}
                      >
                        <MdLabelOutline className="rightIcon" />
                        Who is eligible for an FHA 203B?
                      </ArchonButton>

                      <h6 className="mb-0">
                        According to the FHA, the borrower must meet…
                      </h6>

                      <Collapse in={open}>
                        <div id="collapse-fade-text">
                          <p>
                            The borrower must meet standard FHA credit
                            qualifications:
                          </p>
                          <ul className="listnone bullet">
                            <li>
                              <FiCheckCircle className="bullet-check-circle-default" />{" "}
                              The borrower is eligible for approximately 96.5%
                              financing.
                            </li>
                            <li>
                              <FiCheckCircle className="bullet-check-circle-default" />{" "}
                              The borrower is able to finance the upfront
                              mortgage insurance premium into the mortgage. The
                              borrower will also be responsible for paying an
                              annual premium.
                            </li>
                            <li>
                              <FiCheckCircle className="bullet-check-circle-default" />{" "}
                              Eligible properties are one-to-four unit
                              structures.
                            </li>
                          </ul>
                        </div>
                      </Collapse>
                      <br />

                      <h2 className="">FHA 203K</h2>
                      <p>
                        This FHA loan option allows homeowners to rehabilitate
                        their home. The loan may be used for other home
                        improvement projects such as room additions or making
                        your home more energy-efficient. This option may be used
                        both at the time of purchase of a new home or as a
                        refinance of the existing mortgage.
                      </p>
                      <br />
                      <h2>FHA 203H</h2>
                      <p>
                        This FHA 203H helps victims in disaster areas recover by
                        making it easier for them to get mortgages and become
                        homeowners or re-establish themselves as homeowners.
                        Individuals are eligible for this program if their homes
                        are located in an area that was designated by the
                        President as a disaster area and if their homes were
                        destroyed or damaged to such an extent that
                        reconstruction or replacement is necessary.
                      </p>
                      <ul className="listnone bullet">
                        <li>
                          <FiCheckCircle className="bullet-check-circle-default" />{" "}
                          No down payment is required. The borrower is eligible
                          for 100 percent financing. Closing costs and prepaid
                          expenses must be paid by the borrower in cash or paid
                          through premium pricing or by the seller, subject to a
                          6 percent limitation on seller concessions.
                        </li>
                        <li>
                          {" "}
                          <FiCheckCircle className="bullet-check-circle-default" />
                          Insured mortgages may be used to finance the purchase
                          or reconstruction of a one-family home that will be
                          the principal residence of the homeowner.
                        </li>
                      </ul>
                      <br />
                      <h2>Streamline</h2>
                      <p>
                        A FHA streamline refinance loan helps you lower the
                        interest rate, mortgage payment, or loan term of your
                        existing FHA loan, and has less documentation
                        requirements than traditional programs. Additionally, it
                        does not require your property to be appraised.
                      </p>
                      <p>
                        FHA loans help more home buyers to qualify for a
                        mortgage in order to purchase a home. FHA loans also
                        make it easier for existing homeowners to lower their
                        interest rate and/or monthly payments.
                      </p>

                      <p>
                        For a list of documents required upon closing, please
                        click here:
                        <Link to="./Documents">
                          {" "}
                          CHECKLIST&nbsp;
                          <GoLinkExternal />
                        </Link>
                      </p>
                      <p>
                        Our licensed and experienced mortgage professionals are
                        ready to answer any questions that you may have. <br />
                        Please call us at{" "}
                        <a href="tel:+17877869378">
                          <MdLocalPhone />
                          &nbsp;787&#8209;SUN&#8209;WEST.
                        </a>
                      </p>
                      <div className="text-center">
                        <a
                          className="btn btn-primary btns apply_btn_color"
                          onClick={() => {
                            props.setModalShow(true);
                          }}
                        >
                          Apply Now
                        </a>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
