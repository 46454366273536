import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import StyledHero from '../components/StyledHero'
import FaqImg from '../images/faq.jpg';
import Banner from '../components/Banner';
import { FiCheckCircle } from 'react-icons/fi';
import {Helmet} from "react-helmet";

import './FAQ.css';




export default function FAQ() {


    return (
        <div id="faqWrapper">
            <Helmet>
                <title>FAQ</title>
                <meta name="description" content="LowRates US, Sun West documents FAQ." />
            </Helmet>
            <StyledHero img={FaqImg}>
                <Banner>
                </Banner>
            </StyledHero>            
            <Container>
                <div className="wrapper-content bg-white rounded box-shadow">
                    <div className="p-4 p-sm-5 rounded">
                    <div className="product-tabs">
                        <Row>
                            <Col lg={12}> 
                                <div className="section-title mb30">
                                    <h1>General Questions</h1>
                                </div>
                            </Col>
                            <Col className="faqAccordion" lg={12}> 
                                <Accordion>
                                    <Card>
                                        <Accordion.Collapse eventKey="0" className="faq-answer">
                                        <Card.Body>Sun West does not charge an application fee for you to apply for a mortgage.</Card.Body>
                                        </Accordion.Collapse>
                                        <Accordion.Toggle as={Card.Header} eventKey="0" className="faq-question">
                                        Does it cost anything to apply for a mortgage?
                                        </Accordion.Toggle>
                                    </Card>
                                    <Card>
                                        <Accordion.Collapse eventKey="1" className="faq-answer">
                                        <Card.Body>Sun West has one of the fastest turnaround times in the industry, and we offer 20-day closings, but every borrower&rsquo;s situation is different, and due to documentation requirements and varying response times from the borrower, the average time to close a loan may be higher.</Card.Body>
                                        </Accordion.Collapse>
                                        <Accordion.Toggle as={Card.Header} eventKey="1" className="faq-question">
                                        How long does it take to close my loan?
                                        </Accordion.Toggle>
                                    </Card>
                                    <Card>
                                        <Accordion.Collapse eventKey="2" className="faq-answer">
                                        <Card.Body>A mortgage refinance refers to obtaining a new loan for the purpose of lowering your mortgage payments, converting your existing loan into a more affordable or manageable loan, or getting cash out on available equity on your home.</Card.Body>
                                        </Accordion.Collapse>
                                        <Accordion.Toggle as={Card.Header} eventKey="2" className="faq-question">
                                        What is a refinance?
                                        </Accordion.Toggle>
                                    </Card>
                                    <Card>                                      
                                        <Accordion.Collapse eventKey="3" className="faq-answer">
                                        <Card.Body>Sun West recommends that you check with your existing lender regarding any prepayment penalties. There will likely be fees involved when refinancing your home, although you may have the option of rolling these costs into your new loan.</Card.Body>
                                        </Accordion.Collapse>
                                        <Accordion.Toggle as={Card.Header} eventKey="3" className="faq-question">
                                        Are there fees involved for refinancing?
                                        </Accordion.Toggle>
                                    </Card>
                                    <Card>
                                        <Accordion.Collapse eventKey="4" className="faq-answer">
                                        <Card.Body>
                                            <p>Most types of mortgages require a minimum amount of down payment, except for VA and USDA (RURAL) programs. You may also be eligible for down payment assistance programs that can help you towards minimum down payment requirement on some of the loan programs.</p>
                                            <p>On Conventional Mortgages, your lender will require you to pay a Private Mortgage Insurance (PMI) premium as part of your monthly payments if you put down less than 20% of the purchase price of the property.</p>
                                            <p>There are government loan programs available with less stringent down payment requirements as well, such as an FHA loan that will require only a 3.5% down payment, but again, these programs also require mortgage insurance premiums.</p>
                                        </Card.Body>
                                        </Accordion.Collapse>
                                        <Accordion.Toggle as={Card.Header} eventKey="4" className="faq-question">
                                        What amount of down payment is required in order to obtain a mortgage?
                                        </Accordion.Toggle>
                                    </Card>
                                    <Card>
                                        <Accordion.Collapse eventKey="5" className="faq-answer">
                                        <Card.Body>Mortgage insurance has been established by lenders and government agencies as a means to protect the investor before the mortgage is fully paid off. When a borrower defaults on his or her loan and the property is forced to be sold by foreclosure, the property may be sold for less than the original appraised value. Mortgage insurance, in such cases, allows the investor to file a claim with the insurance issuer to claim a partial or full portion of the amount of loss.
                                        </Card.Body>
                                        </Accordion.Collapse>
                                        <Accordion.Toggle as={Card.Header} eventKey="5" className="faq-question">
                                        Why is mortgage insurance required?
                                        </Accordion.Toggle>
                                    </Card>
                                    <Card>
                                        <Accordion.Collapse eventKey="6" className="faq-answer">
                                        <Card.Body>The Loan-to-Value (LTV) is a percentage of your loan in relation to your property&rsquo;s appraised value or purchase price, whichever is lower. The LTV is one of the key risk measures used by mortgage banks to determine the size of the loan for which the borrower can qualify. The higher the LTV, the more risk for the lender and the less likely that a borrower will qualify for a loan.                                            
                                        </Card.Body>
                                        </Accordion.Collapse>
                                        <Accordion.Toggle as={Card.Header} eventKey="6" className="faq-question">
                                        What is LTV?
                                        </Accordion.Toggle>
                                    </Card>
                                    <Card>
                                        <Accordion.Collapse eventKey="7" className="faq-answer">
                                        <Card.Body><p>Your mortgage interest rate is dependent upon a number of factors, including, but not limited to your credit score, size of down payment, loan purpose, occupancy, LTV ratio, and DTI ratio. Different mortgage products have different guidelines that may also indirectly affect what rates you can get.</p>
                                        <p>The base interest rate in the market is determined by the secondary market through competing U.S. Treasury bonds, the price of which is determined by the market demand. All these factors combined will determine what interest rate you will get for your mortgage at a particular time. When you are ready to secure your interest rate during your mortgage application, you should contact your licensed loan officer to go over the lock process in more detail.  </p>
                                        </Card.Body>
                                        </Accordion.Collapse>
                                        <Accordion.Toggle as={Card.Header} eventKey="7" className="faq-question">
                                        What determines my mortgage interest rate?
                                        </Accordion.Toggle>
                                    </Card>
                                    <Card> 
                                        <Accordion.Collapse eventKey="8" className="faq-answer">
                                        <Card.Body>Closing costs are the fees associated with acquiring a mortgage loan. Such closing costs include but are not limited to:
                                        <ol className="listnone bullet">
                                            <li><FiCheckCircle className="bullet-check-circle-default" />Lender fees</li>
                                            <li><FiCheckCircle className="bullet-check-circle-default" />Origination fee</li>
                                            <li><FiCheckCircle className="bullet-check-circle-default" />Discount points</li>
                                            <li><FiCheckCircle className="bullet-check-circle-default" />Third party service fees for appraisal, credit report, flood certification, etc.</li>
                                            <li><FiCheckCircle className="bullet-check-circle-default" />Title and escrow / attorney charges</li>
                                            <li><FiCheckCircle className="bullet-check-circle-default" />Transfer / intangible / mortgage tax as required by the city, state, etc.</li>
                                            <li><FiCheckCircle className="bullet-check-circle-default" />Per diem interest</li>
                                        </ol>
                                        <p>The lender is required by law to provide to you a preliminary estimate after you submit your application.</p>
                                        </Card.Body>
                                        </Accordion.Collapse>
                                        <Accordion.Toggle as={Card.Header} eventKey="8" className="faq-question">
                                        What are closing costs?
                                        </Accordion.Toggle>
                                    </Card>
                                    <Card>
                                        <Accordion.Collapse eventKey="9" className="faq-answer">
                                        <Card.Body>The origination charge includes fees for services from loan application, preparation, underwriting, and processing. The origination fee is the fee(s) earned by the lender and/or broker for providing you the service when making the loan.
                                        </Card.Body>
                                        </Accordion.Collapse>
                                        <Accordion.Toggle as={Card.Header} eventKey="9" className="faq-question">
                                        What are origination charges?
                                        </Accordion.Toggle>
                                    </Card>
                                    <Card>
                                        <Accordion.Collapse eventKey="10" className="faq-answer">
                                        <Card.Body>This process is formally known as a rate lock. After you submit your application, you are eligible to lock your loan. If you do not wish to lock at the time of application, you may choose to check with your loan officer regularly to check the current interest rate and then lock at a later time. It is important to note that until you lock your loan, your interest is subject to market fluctuations and may go up until it is locked. The expiration of your rate lock must be good until the date of funding of your mortgage loan.
                                        </Card.Body>
                                        </Accordion.Collapse>
                                        <Accordion.Toggle as={Card.Header} eventKey="10" className="faq-question">
                                        What is &quot;locking&quot; your loan?
                                        </Accordion.Toggle>
                                    </Card>
                                    <Card>
                                        <Accordion.Collapse eventKey="11" className="faq-answer">
                                        <Card.Body>An appraisal is required by your lender to professionally determine the value of the property you wish to buy. A licensed appraiser will be chosen to inspect the features of the house, the quality of the build, age, etc. The appraiser will then compare the asking price of the property to recent home sale data in the region to see if it is of fair market value. A lender will not approve a loan where the purchase price is greater than the appraised value unless you agree to bring in the difference of the two as part of your funds at closing.
                                        </Card.Body>
                                        </Accordion.Collapse>
                                        <Accordion.Toggle as={Card.Header} eventKey="11" className="faq-question">
                                        What is the appraisal?
                                        </Accordion.Toggle>
                                    </Card>
                                    <Card>
                                        <Accordion.Collapse eventKey="12" className="faq-answer">
                                        <Card.Body>Since the appraisal is primarily used so the lender will know that they are not approving a loan in excess of the property value, lenders will still only give you a loan for the lower of the two.
                                        </Card.Body>
                                        </Accordion.Collapse>
                                        <Accordion.Toggle as={Card.Header} eventKey="12" className="faq-question">
                                        What if the appraised value of my selected property is higher than the sales price?
                                        </Accordion.Toggle>
                                    </Card>
                                    <Card>
                                        <Accordion.Collapse eventKey="13" className="faq-answer">
                                        <Card.Body>
                                            <p>There are a few insurance types that are required when you get your mortgage, and each has a unique purpose.</p>
                                                <ol>
                                                    <li>Private Mortgage Insurance (PMI): This is an insurance coverage that your lender will require you to purchase if your down payment is less than 20% of the loan amount. The purpose of this insurance is to protect the lender&rsquo;s investment on the loan. This is generally paid from your escrow account each month and is collected together with your monthly mortgage payment.</li>
                                                    <li>FHA Mortgage Insurance: This mortgage insurance serves the same purpose as a PMI, but has a different name because it is charged by your lender for taking out FHA government insured loans when you do not meet LTV requirements. You will be required to pay a portion as an upfront premium during closing, plus a monthly amount.</li>
                                                    <li>Hazard Insurance: It is homeowner&rsquo;s insurance required by the lender to protect their investment against damage to the property, before the loan is paid in full. Note that a separate Flood Insurance may be required in certain areas, in addition to Hazard Insurance.</li>
                                                    <li>Title Insurance: Just as the name suggests, Title Insurance is indemnity insurance purchased to protect the property title. The title insurance protects against title defects and any possible lawsuits against it due to documentation deficiencies or lien claims. It is important because it protects both the lender and borrower&rsquo;s interests.</li>
                                                </ol>
                                        </Card.Body>
                                        </Accordion.Collapse>
                                        <Accordion.Toggle as={Card.Header} eventKey="13" className="faq-question">
                                        What are the different types of insurance required when getting a mortgage?
                                        </Accordion.Toggle>
                                    </Card>
                                    <Card>
                                        <Accordion.Collapse eventKey="14" className="faq-answer">
                                        <Card.Body>With respect to mortgages, equity refers to the difference between the market value of the property and the amount currently owed on the mortgage. It is basically the amount of money the owner will be able to keep for him or herself after selling the house and after paying off the remaining mortgage amount.
                                        </Card.Body>
                                        </Accordion.Collapse>
                                        <Accordion.Toggle as={Card.Header} eventKey="14" className="faq-question">
                                        What is the meaning of equity?
                                        </Accordion.Toggle>
                                    </Card>
                                    <Card> 
                                        <Accordion.Collapse eventKey="15" className="faq-answer">
                                        <Card.Body>
                                            <p>The term escrow can refer to two things: the escrow process or an escrow account. The escrow process is an important part of the mortgage origination where a third party company is assigned to keep control of all funds and documents for all parties (seller, buyer, lender) in the transaction. Funds are sent to the escrow company to be processed and verified, and the escrow company, upon disbursement, prepares a settlement statement summarizing the distribution of funds and the terms of your mortgage.</p>
                                            <p>An escrow account usually refers to an account for a mortgage by a lender to hold funds that will be used to pay towards property taxes and insurance, including mortgage insurance. The payments made from an escrow account will be shown in your monthly mortgage statements, so you don&rsquo;t have to worry about managing these payments separately.</p>
                                        </Card.Body>
                                        </Accordion.Collapse>
                                        <Accordion.Toggle as={Card.Header} eventKey="15" className="faq-question">
                                        What is Escrow?
                                        </Accordion.Toggle>
                                    </Card>
                                </Accordion>                                              
                            </Col>
                        </Row>
                        </div>
                     </div>
                </div>
            </Container>              
        </div>
    )
}
