import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import StyledHero from "../components/StyledHero";
import RenoImg from "../images/reno.jpeg";
import Banner from "../components/Banner";
import { FiCheckCircle } from "react-icons/fi";
import { GoLinkExternal } from "react-icons/go";
import { MdLocalPhone } from "react-icons/md";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function Renovation(props) {
  return (
    <div id="renoWrapper">
      <Helmet>
        <title>Renovation Loans</title>
        <meta
          name="description"
          content="Sun West is the only mortgage bank in Puerto Rico that offers the HomeStyle® Renovation mortgage."
        />
      </Helmet>
      <StyledHero img={RenoImg}>
        <Banner title="Home Style&reg; Renovation"></Banner>
      </StyledHero>
      <Container>
        <div className="wrapper-content bg-white rounded box-shadow">
          <div className="pinside40 rounded">
            <div className="product-tabs">
              <Row>
                <Col lg={12} className="nopadding">
                  <div className="tab-content">
                    <div
                      role="tabpanel"
                      className="tab-pane active"
                      id="overview"
                    >
                      <h2>About Home Style&reg; Renovation Mortgage</h2>
                      <p>
                        Sun West is the only mortgage bank in Puerto Rico that
                        offers the HomeStyle&reg; Renovation mortgage, a
                        government-backed loan that allows qualified borrowers
                        to add extra money for remodeling or improvements to an
                        initial home purchase mortgage or a mortgage
                        refinancing.
                      </p>
                      <h3>
                        Home Style Renovation Mortgage Benefits and Highlights
                      </h3>
                      <ul className="listnone bullet">
                        <li>
                          <FiCheckCircle className="bullet-check-circle-default" />
                          Available as{" "}
                          <Link to="./fixedmortgage">
                            15- or 30-year fixed-rate mortgages
                          </Link>
                          , or as adjustable rate mortgages
                        </li>
                        <li>
                          <FiCheckCircle className="bullet-check-circle-default" />
                          Down payments can be as small as 5 percent, and
                          borrowers avoid fees and closing costs associated with
                          taking out a second mortgage.
                        </li>
                      </ul>

                      <p>
                        For a list of documents required upon closing, please
                        click here:
                        <Link to="./Documents">
                          {" "}
                          CHECKLIST&nbsp;
                          <GoLinkExternal />
                        </Link>
                      </p>
                      <p>
                        Our licensed and experienced mortgage professionals are
                        ready to answer any questions that you may have. <br />
                        Please call us at{" "}
                        <a href="tel:+17877869378">
                          <MdLocalPhone />
                          &nbsp;787&#8209;SUN&#8209;WEST.
                        </a>
                      </p>
                      <div className="text-center">
                        <a
                          //   href="https://www.swmc.com/ApplyNow/?extLeadSource=LOWRATESUS"
                          //   target="_blank"
                          //   rel="noopener noreferrer"
                          className="btn btn-primary btns apply_btn_color"
                          onClick={() => {
                            props.setModalShow(true);
                          }}
                        >
                          Apply Now
                        </a>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
