import React, { useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";
import { genericHashLink } from "react-router-hash-link";
import "./Home.css";
import IvanVideo from "../components/Video";
import { Helmet } from "react-helmet";

import GenerateLead from "../components/GenerateLead";

const MyHashLink = (props) => genericHashLink(props, Link);

const Home = (props) => {
  const scrollRef = useRef(null);
  const executeScroll = () => scrollRef.current.scrollIntoView();

  return (
    <div>
      <Helmet>
        <title>Time to Step Up to Home!</title>
        <meta
          name="description"
          content="Welcome to Sun West Mortgage Company, a friendly mortgage bank doing business in 48 states, DC, Puerto Rico and the US Virgin Islands."
        />
      </Helmet>
      <section id="home" className="nexa-banner-business">
        <div
          id="carousel-banner"
          className="carousel slide"
          data-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="item">
              <div className="banner-img">
                <Image
                  src="./assest/ivanR_glove.png"
                  srcSet="./assest/ivanR_glove.png 634w,
                            ./assest/ivanR_glove-sm.png 300w"
                  alt="Ivan Rodriguez posing with a baseball globe and ball."
                  className="clip-css-banner float-right"
                />
                <div className="overlay-banner"></div>
              </div>
            </div>
          </div>
          <div className="banner-shape">
            <Image
              src="./assest/container.png"
              className="img-fluid"
              alt="Bottom white shape"
            />
          </div>
        </div>
        <Container>
          <div className="banner-inner-info">
            <div className="banner-title">
              <h4>Time to Step Up to Home</h4>
              <h2>Get started today by filling out the APPLY…</h2>
              <div className="btn-video">
                <button
                  className="btn btn-success"
                  onClick={() => {
                    executeScroll();
                    props.setModalShow(true);
                  }}
                >
                  Apply Now
                </button>
                <MyHashLink
                  className="btn btn-success"
                  smooth
                  to="./#contactUsSpan"
                >
                  Contact Us
                </MyHashLink>
              </div>
            </div>
          </div>
          <div className="banner-inner-info2 d-block d-sm-block d-md-none">
            <div className="banner-title">
              <div className="btn-video">
                <button
                  className="btn btn-success"
                  onClick={() => {
                    executeScroll();
                    props.setModalShow(true);
                  }}
                >
                  Apply Now
                </button>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <br />
      <section
        ref={scrollRef}
        className="nexa-portfolio-business"
        id="messageBox"
      >
        <Container>
          <Row>
            <Col className="text-center">
              <p>
                {" "}
                Welcome to <span>Sun West Mortgage Company</span>, a friendly
                mortgage bank doing business in
                <br />
                48 states, DC, Puerto Rico and the US Virgin Islands. Sun West
                is your one-stop-shop to buy a <br /> home or refinance a
                property, offering a complete line of mortgage products,
                including
                <br />
                <Link to="./Conventional">Conventional</Link>,{" "}
                <Link to="./Fha">FHA</Link>, <Link to="./Va">VA</Link>,{" "}
                <Link to="./Renovation">Home Improvements</Link> and{" "}
                <Link to="./Reverse">Reverse mortgages</Link>.
              </p>
              <br />
              <p>
                Get Started today by filling out the{" "}
                <button
                  onClick={() => {
                    props.setModalShow(true);
                  }}
                >
                  APPLY
                </button>{" "}
                form or by calling one of our Loan Officers at{" "}
                <a href="tel:+17877869378">787-SUN-WEST.</a>
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <br />
      <br />
      <section className="nexa-portfolio-business">
        <div className="text-center" id="imgBox">
          <Image src="./assest/bluehouse.png" alt="Blue House" />
        </div>
      </section>

      <section className="nexa-portfolio-business" id="btnsSection">
        <div
          id="carousel-portfolio"
          className="carousel slide"
          data-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="item active">
              <Container>
                <Row>
                  <Col xs={12} sm={6} md={3}>
                    <div className="portfolio-details">
                      <Link to="./Buying">
                        <h2>Buying A Home</h2>
                      </Link>
                    </div>
                  </Col>
                  <Col xs={12} sm={6} md={3}>
                    <div className="portfolio-details">
                      <Link to="./Refinancing">
                        <h2>Refinance Your Property</h2>
                      </Link>
                    </div>
                  </Col>
                  <Col xs={12} sm={6} md={3}>
                    <div className="portfolio-details">
                      <button
                        onClick={() => {
                          props.setModalShow(true);
                        }}
                      >
                        <h2>Apply Now</h2>
                      </button>
                    </div>
                  </Col>
                  <Col xs={12} sm={6} md={3}>
                    <div className="portfolio-details">
                      <a
                        href="https://seemyloanstatus.com/ReverseMortgage/jsp/extSeeMyLoanStatusLogin.jsf?loginByUsingView=LOAN"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <h2>See My Loan Status</h2>
                      </a>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
      </section>
      <IvanVideo />
      <span id="contactUsSpan"></span>
      <br />
      <br />
      <br />
      <div id="contactUsForm">
        <GenerateLead header="Contact Us" />
      </div>
    </div>
  );
};

export default Home;
