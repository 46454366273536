import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import StyledHero from "../components/StyledHero";
import MapImg from "../images/sitemap.jpg";
import Banner from "../components/Banner";
import { Link, withRouter } from "react-router-dom";
import { genericHashLink } from "react-router-hash-link";
import { Helmet } from "react-helmet";

const MyHashLink = (props) => genericHashLink(props, Link);

function Sitemap(props) {
  return (
    <div id="sitemapWrapper">
      <Helmet>
        <title>Sitemap</title>
        <meta
          name="description"
          content="Sun West is your one-stop-shop to buy a home or refinance a property, offering a complete line of mortgage products, including Conventional, FHA, VA, Home Improvements and Reverse mortgages."
        />
      </Helmet>
      <StyledHero img={MapImg}>
        <Banner title="Sitemap"></Banner>
      </StyledHero>
      <Container>
        <Row>
          <Col className="p-5" lg={12}>
            <section className="pricing-table">
              <Container>
                <Row>
                  <Col sm={12} md={6} lg={4} className="mb-3">
                    <h3>
                      <Link to="./">
                        <b>HOME</b>
                      </Link>
                    </h3>
                    <h3>
                      <b>
                        <Link to="./About">ABOUT US</Link>
                      </b>
                    </h3>
                    <h3>
                      <b>LOAN PROGRAMS</b>
                    </h3>
                    <p>
                      <Link to="./Conventional">Conventional Loans</Link>
                    </p>
                    <p>
                      <Link to="./Fha">FHA Loans</Link>
                    </p>
                    <p>
                      <Link to="./Renovation">
                        Renovation and Construction Loans
                      </Link>
                    </p>
                    <p>
                      <Link to="./Va">VA Loans</Link>
                    </p>
                    <p>
                      <Link to="./Reverse">Reverse Mortgage</Link>
                    </p>
                  </Col>
                  <Col sm={12} md={6} lg={4} className="mb-3">
                    <h3>
                      <b>RESOURCES</b>
                    </h3>
                    <p>
                      <Link to="./Buying">Home Buyers Guide</Link>
                    </p>
                    <p>
                      <Link to="./Refinancing">Refinancing Guide</Link>
                    </p>
                    <p>
                      <Link to="./Calculators">Mortgage Calculators</Link>
                    </p>
                    <p>
                      <Link to="./Documents">Documents Needed</Link>
                    </p>
                    <br />
                    <h3>
                      <b>
                        <Link to="./Team">TEAM</Link>
                      </b>
                    </h3>
                    <h3>
                      <b>TALK TO US</b>
                    </h3>
                    <p>
                      <Link to="./Faq">FAQ</Link>
                    </p>
                    <p>
                      <MyHashLink smooth to="./#contactUsSpan">
                        Contact Us
                      </MyHashLink>
                    </p>
                    <p>
                      <a
                        // href="https://www.swmc.com/ApplyNow/?extLeadSource=LOWRATESUS"
                        // target="_blank"
                        // rel="noopener noreferrer"
                        onClick={() => {
                          props.setModalShow(true);
                          
                          
                        }}
                      >
                        Apply Now
                      </a>
                    </p>
                  </Col>
                  <Col sm={12} md={6} lg={4}>
                    <h3>
                      <b>ADDITIONAL PAGES</b>
                    </h3>
                    <p>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.swmc.com/privacy-policy.php"
                      >
                        Privacy Policy
                      </a>
                    </p>
                    <p>
                      <Link to="./Terms">Terms &amp; Conditions</Link>
                    </p>
                    <p>
                      <Link to="./disclaimer">Disclaimer</Link>
                    </p>
                  </Col>
                </Row>
              </Container>
            </section>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default withRouter(Sitemap);
