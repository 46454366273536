import React from "react";
import { Link, withRouter } from "react-router-dom";
import { genericHashLink } from "react-router-hash-link";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Image from "react-bootstrap/Image";
import { FaHome } from "react-icons/fa";
import "./Navbar.css";

const MyHashLink = (props) => genericHashLink(props, Link);

const NavDesktop = (props) => {

  return (
    <div className="desktopNavAll">
      <Navbar
        id="mainNav"
        className="scrolled"
        fixed="top"
        collapseOnSelect
        expand="md"
        variant="dark"
      >
        <Container>
          <Row className="w-100 m-auto align-items-center">
            <Col
              xs={9}
              sm={10}
              md={10}
              lg={3}
              xl={3}
              className="text-center text-sm-center text-md-center text-lg-left mr-3 mr-sm-4"
            >
              <Navbar.Brand className="mr-sm-0" as={Link} to="./">
                <Image
                  className="img-fluid"
                  srcSet="./assest/logo-sm.png 250w, 
                                ./assest/logo-md.png 350w, 
                                ./assest/logo-business.png 456w"
                  sizes="(max-width: 580px) 200px,
                                       (max-width: 780px) 350px,
                                        456px"
                  src="./assest/logo-business.png"
                  alt="Sun West Mortgage Company, Inc. Logo"
                />
              </Navbar.Brand>
            </Col>
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mr-auto">
                <Nav.Item>
                  <Nav.Link as={Link} to="./" className="navLink">
                    Home <FaHome className="homeIcon" />
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link as={Link} to="./About">
                    About Us
                  </Nav.Link>
                </Nav.Item>
                <NavDropdown title="Loan Programs" id="loanProgramsDesktop">
                  <NavDropdown.Item className="navLink" as={Link} to="./Fha">
                    FHA
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item
                    to="./Conventional"
                    as={Link}
                    className="navLink"
                  >
                    Conventional
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item as={Link} className="navLink" to="./Va">
                    VA
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item
                    className="navLink"
                    as={Link}
                    to="./Renovation"
                  >
                    Home Style Renovation
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item
                    className="navLink"
                    as={Link}
                    to="./Reverse"
                  >
                    Reverse Mortgage
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="Resources" id="resources">
                  <NavDropdown.Item as={Link} to="./Buying" className="navLink">
                    Home Buyer&#39;s Guide
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item
                    className="navLink"
                    as={Link}
                    to="./Refinancing"
                  >
                    Refinancing Guide
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item
                    className="navLink"
                    as={Link}
                    to="./Documents"
                  >
                    Documents Needed
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item
                    className="navLink"
                    as={Link}
                    to="./Calculators"
                  >
                    Mortgage Calculators
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link className="navLink" as={Link} to="./Team">
                  Team
                </Nav.Link>
                <NavDropdown title="Talk To Us" id="talkToUs">
                  <NavDropdown.Item className="navLink" as={Link} to="./Faq">
                    FAQ
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <MyHashLink
                    className="navLink dropdown-item"
                    smooth
                    to="./#contactUsSpan"
                  >
                    Contact Us
                  </MyHashLink>
                  <NavDropdown.Divider />
                  <NavDropdown.Item
                    className="navLink"
                    onClick={() => {
                      props.setModalShow(true);
                    }}
                  >
                    Apply Now
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </Row>
        </Container>
      </Navbar>
    </div>
  );
};

export default withRouter(NavDesktop);
