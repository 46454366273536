import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import StyledHero from "../components/StyledHero";
import ReveseImg from "../images/reverse.jpg";
import Banner from "../components/Banner";
import { FiCheckCircle } from "react-icons/fi";
import { GoLinkExternal } from "react-icons/go";
import { MdLocalPhone } from "react-icons/md";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function Reverse(props) {
  return (
    <div id="reveWrapper">
      <Helmet>
        <title>Reverse Mortgage</title>
        <meta
          name="description"
          content="A reverse mortgage is a loan available to homeowners, 62 years or older that allows them to convert part of the equity in their homes into cash."
        />
      </Helmet>
      <StyledHero img={ReveseImg}>
        <Banner title="Reverse Mortgage"></Banner>
      </StyledHero>
      <Container>
        <div className="wrapper-content bg-white rounded box-shadow">
          <div className="pinside40 rounded">
            <div className="product-tabs">
              <Row>
                <Col lg={12} className="nopadding">
                  <div className="tab-content">
                    <div
                      role="tabpanel"
                      className="tab-pane active"
                      id="overview"
                    >
                      <h2>About Reverse Mortgage</h2>
                      <p>
                        A reverse mortgage is a loan available to homeowners, 62
                        years or older that allows them to convert part of the
                        equity in their homes into cash. The product helps
                        retirees with limited income, to use the accumulated
                        wealth in their homes to cover basic monthly living
                        expenses and pay for health care, or any other need they
                        may have, as there is no restriction on how reverse
                        mortgage proceeds can be used.
                        <br />
                        The loan is called a reverse mortgage because instead of
                        making monthly payments to a lender, as with a
                        traditional mortgage, the lender makes payments to the
                        borrower
                      </p>
                      <h3>Reverse Mortgage Benefits and Highlights</h3>
                      <ul className="listnone bullet">
                        <li>
                          <FiCheckCircle className="bullet-check-circle-default" />
                          Borrower is not required to pay back the loan until
                          the home is sold or otherwise vacated.{" "}
                        </li>
                        <li>
                          <FiCheckCircle className="bullet-check-circle-default" />
                          Borrower is not required to make any monthly payments
                          towards the loan balance, as long as they live in that
                          home.
                        </li>
                        <li>
                          <FiCheckCircle className="bullet-check-circle-default" />
                          Borrower must remain current on property taxes,
                          homeowners insurance and homeowner&rsquo;s association
                          dues (if applicable).
                        </li>
                      </ul>

                      <p>
                        For a list of documents required upon closing, please
                        click here:
                        <Link to="./Documents">
                          {" "}
                          CHECKLIST&nbsp;
                          <GoLinkExternal />
                        </Link>
                      </p>
                      <p>
                        Our licensed and experienced mortgage professionals are
                        ready to answer any questions that you may have. <br />
                        Please call us at{" "}
                        <a href="tel:+17877869378">
                          <MdLocalPhone />
                          &nbsp;787&#8209;SUN&#8209;WEST.
                        </a>
                      </p>
                      <div className="text-center">
                        <a
                          className="btn btn-primary btns apply_btn_color"
                          onClick={() => {
                            props.setModalShow(true);
                            
                            
                          }}
                        >
                          Apply Now
                        </a>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
