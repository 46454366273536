import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import StyledHero from "../components/StyledHero";
import VaImg from "../images/va.jpeg";
import Banner from "../components/Banner";
import { FiCheckCircle } from "react-icons/fi";
import { GoLinkExternal } from "react-icons/go";
import { MdLocalPhone } from "react-icons/md";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function Va(props) {
  return (
    <div id="vaWrapper">
      <Helmet>
        <title>VA Loans</title>
        <meta
          name="description"
          content="Guaranteed by the U.S. Department of Veteran Affairs, the VA loan program is designed to assist both current active duty military members and veterans."
        />
      </Helmet>
      <StyledHero img={VaImg}>
        <Banner title="VA Mortgage"></Banner>
      </StyledHero>
      <Container>
        <div className="wrapper-content bg-white rounded box-shadow">
          <div className="pinside40 rounded">
            <div className="product-tabs">
              <Row>
                <Col lg={12} className="nopadding">
                  <div className="tab-content">
                    <div
                      role="tabpanel"
                      className="tab-pane active"
                      id="overview"
                    >
                      <h2>About VA Mortgage</h2>
                      <p>
                        Guaranteed by the U.S. Department of Veteran Affairs,
                        the VA loan program is designed to assist both current
                        active duty military members and veterans. Eligible
                        borrowers for this program are allowed to borrow at a
                        higher percentage of the home value and the government
                        insures the loan.{" "}
                      </p>
                      <h3>VA Mortgage Benefits and Highlights</h3>
                      <ul className="listnone bullet">
                        <li>
                          <FiCheckCircle className="bullet-check-circle-default" />
                          Available to all active duty military personnel,
                          veterans, military reserve and National Guard members,
                          and their surviving spouses if they do not remarry.
                        </li>
                        <li>
                          <FiCheckCircle className="bullet-check-circle-default" />
                          No down payment required.
                        </li>
                        <li>
                          <FiCheckCircle className="bullet-check-circle-default" />
                          No monthly mortgage insurance payments required.
                        </li>
                        <li>
                          <FiCheckCircle className="bullet-check-circle-default" />
                          Available as both fixed and adjustable rate options.
                        </li>
                        <li>
                          <FiCheckCircle className="bullet-check-circle-default" />
                          Eligible credit scores as low as 500.
                        </li>
                        <li>
                          <FiCheckCircle className="bullet-check-circle-default" />
                          Ability to finance the VA Funding Fee into the
                          mortgage.
                        </li>
                        <li>
                          <FiCheckCircle className="bullet-check-circle-default" />
                          Active duty military members may be eligible to use
                          certain military entitlements to pay for their
                          mortgage.
                        </li>
                      </ul>
                      <p>
                        Sun West is here to help you through the application
                        process to obtain your VA loan and will help determine
                        if you are eligible.
                      </p>

                      <p>
                        For a list of documents required upon closing, please
                        click here:
                        <Link to="./Documents">
                          {" "}
                          CHECKLIST&nbsp;
                          <GoLinkExternal />
                        </Link>
                      </p>
                      <p>
                        Our licensed and experienced mortgage professionals are
                        ready to answer any questions that you may have. Please
                        call us at{" "}
                        <a href="tel:+17877869378">
                          <MdLocalPhone />
                          &nbsp;787&#8209;SUN&#8209;WEST.
                        </a>
                      </p>
                      <div className="text-center">
                        <Link
                          className="btn btn-primary btns"
                          as={Link}
                          to="./Calculators"
                        >
                          Calculator
                        </Link>
                        <a
                          className="btn btn-primary btns apply_btn_color"
                          onClick={() => {
                            props.setModalShow(true);
                          }}
                        >
                          Apply Now
                        </a>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
