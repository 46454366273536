import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import StyledHero from '../components/StyledHero';
import AboutImg from '../images/about.jpg';
import Banner from '../components/Banner';
import {Helmet} from "react-helmet";



export default function About() {

    return (
        <div id="aboutWapper">
            <Helmet>
                <title>About Us</title>
                <meta name="description" content="For over 7 years, Sun West has been part of Puerto Rico's business community and community efforts" />
            </Helmet>
            <StyledHero img={AboutImg}>
                <Banner title="About Us">
                </Banner>
            </StyledHero>            
            <Container>
                <div className="wrapper-content bg-white rounded box-shadow">
                    <div className="pinside40 rounded">
                        <Row>
                            <Col sm={12} md={6} className="mb-5">
                                <h2 className="mb30 text-center">Who We Are</h2>
                                <p>Sun West Mortgage Company, Inc., is a friendly and agile mortgage bank doing business in over 45 states, D.C., Puerto Rico and the US Virgin Islands. The Company&#39;s headquarters are in California and Puerto Rico, and it has operations in 48 states in the United States. Sun West Mortgage is your one-stop-shop to buy a home or refinance a property, offering a complete line of mortgage products, including Conventional, FHA, VA, Home Improvements and Reverse mortgages.</p>            
                            </Col>
                            <Col sm={12} md={6}>
                                <div className="mb60 mb-sm-0">
                                    <h2 className="mb30 text-center">Our Process</h2>
                                    <p>Our Mortgage Loan Officers (MLO's) will provide orientation and help when you apply and complete your mortgage loan, throughout the process until closing. We will visit your home or office, to make it easier and more customer-friendly.</p> 
                                </div>           
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={6}>
                                <div className="text-center section-space60 pt-0 pt-sm-5">
                                    <h2 className="mb30">Community Involvement</h2>
                                    <p>For over 7 years, Sun West has been part of Puerto Rico's business community and community efforts. The Company prides itself in helping others through its team members, donations and community efforts.</p>
                                    <p>After Hurricane Maria in 2017, Sun West's team joined together to provide food and water to many communities in desperate need after the devastation left by the storm.</p>
                                    <p>Sun West is also involved in helping little league players, with the support of friend and spokesperson Iván "Pudge" Rodríguez, an MLB Hall of Fame inductee.</p>
                                </div>
                            </Col>
                            <Col sm={12} md={6} className="text-center">
                                <Image src="./assest/ivanwithhand.jpg" className="img-thumbnail img-fluid box-shadow" alt="Ivan Rodriguez" />
                            </Col>
                        </Row>
                     </div>
                </div>
            </Container>              
        </div>
    )
}
