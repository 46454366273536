import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import StyledHero from "../components/StyledHero";
import BuyImg from "../images/buy.jpeg";
import Banner from "../components/Banner";
import { FiCheckCircle } from "react-icons/fi";
import { GoLinkExternal } from "react-icons/go";
import { MdLocalPhone } from "react-icons/md";
import { GiShakingHands } from "react-icons/gi";
import { GiReceiveMoney } from "react-icons/gi";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function Buying(props) {
  return (
    <div id="buyingWrapper">
      <Helmet>
        <title>Buying A Home</title>
        <meta
          name="description"
          content="Sun West Mortgage Company can help you get started, and we have a complete line of financing options so you may find a mortgage that best suits you."
        />
      </Helmet>
      <StyledHero img={BuyImg}>
        <Banner title="Buying A Home"></Banner>
      </StyledHero>
      <Container>
        <div className="wrapper-content bg-white rounded box-shadow">
          <div className="pinside40 rounded">
            <div className="product-tabs">
              <Row>
                <Col lg={12}>
                  <div className="lender-details">
                    <div className="lender-head-sections">
                      <Row>
                        <Col sm={12} md={3}>
                          <div className="lender-img">
                            <Image
                              src="./assest/Logo-small.png"
                              className="img-fluid"
                              alt="Sun West Mortgage Company, Inc. logo"
                            />
                          </div>
                        </Col>
                        <Col className="mb-3" sm={12} md={7}>
                          <h2 className="lender-name">
                            Sun West Mortgage Company
                          </h2>
                          <p>
                            At Sun West Mortgage Company, Inc., we understand
                            that every borrower&rsquo;s needs are different.
                            Whether you are looking to purchase a home or
                            refinance your current mortgage, rest assured that
                            our friendly and knowledgeable staff will assist you
                            in finding the perfect loan for your home from a
                            complete line of mortgage products.
                          </p>
                        </Col>
                        <Col sm={12} className="text-center">
                          <a
                            className="btn btn-sm rounded box-shadow btn-primary apply_btn_color"
                            onClick={() => {
                              props.setModalShow(true);
                            }}
                          >
                            Apply Now
                          </a>
                        </Col>
                      </Row>
                    </div>
                    <div className="mb30">
                      <div className="bg-sun pinside20 borderTop">
                        <h3 className="mb0 text-white text-center">
                          Conventional (One of Our Program Options)
                        </h3>
                      </div>
                      <div className="outline pinside20 box-shadow borderBottom">
                        <Row>
                          <Col sm={6} md={4} lg={4}>
                            <div className="text-center">
                              <h3 className="rate">5.000% Rate</h3>
                              <h3 className="rate">5.240% APR</h3>
                              <small>10-Year Fixed</small>
                            </div>
                          </Col>
                          <Col sm={6} md={4} lg={4}>
                            <div className="text-center">
                              <h3 className="fees">4.875% Rates</h3>
                              <h3 className="fees">5.115% APR</h3>
                              <small>15-Year Fixed</small>
                            </div>
                          </Col>
                          <Col sm={6} md={4} lg={4}>
                            <div className="text-center">
                              <h3 className="compare-rate">5.125% Rates</h3>
                              <h3 className="compare-rate">5.365% APR</h3>
                              <small>30-Year Fixed</small>
                            </div>
                          </Col>
                          {/* "See All Programs" button is commented as /rates page was showing old rates */}
                          {/* <Col
                            className="text-center p-0"
                            sm={12}
                            md={12}
                            lg={3}
                          >
                            <div className="compare-action">
                              {" "}
                              <a
                                href="./rates"
                                className="btn btn-primary btn-sm rounded box-shadow p-3"
                              >
                                See All Programs
                              </a>{" "}
                            </div>
                          </Col> */}
                        </Row>
                      </div>
                    </div>
                    <div className="lender-loan-sections">
                      <Row>
                        <Col sm={12} className="text-md-left text-lg-center">
                          <h2 className="mb30">Reasons To Buy</h2>
                        </Col>
                        <Col
                          xs={12}
                          sm={6}
                          className="text-center text-sm-center text-md-left mb-4"
                        >
                          <GiShakingHands className="icon-4x icon-primary mb-2" />
                          <h3>Trusted Finance</h3>
                          <p>
                            Sun West Mortgage Company can help you get started,
                            and we have a complete line of financing options so
                            you may find a mortgage that best suits you.
                          </p>
                        </Col>
                        <Col
                          xs={12}
                          sm={6}
                          className="text-center text-sm-center text-md-left"
                        >
                          <GiReceiveMoney className="icon-4x icon-primary mb-3" />
                          <h3>Investing in Your Future</h3>
                          <p>
                            Owning your home is the American Dream, and by being
                            a homeowner, you don&rsquo;t just invest in your
                            home but also provide a stable environment to raise
                            your family.
                          </p>
                        </Col>
                      </Row>
                    </div>
                    <div className="lender-features">
                      <Row>
                        <Col sm={12}>
                          <h2 className="mb30">Features &amp; Benefits</h2>
                        </Col>
                        <Col sm={12} md={4}>
                          <div className="feature">
                            <div className="feature-content">
                              <h4>A Secure Retirement</h4>
                              <p>
                                A home can be the ultimate nest egg, providing
                                you with a great investment for retirement.
                              </p>
                            </div>
                          </div>
                        </Col>
                        <Col sm={12} md={4}>
                          <div className="feature">
                            <div className="feature-content">
                              <h4>Tax Benefits</h4>
                              <p>
                                The many expenses of owning a home—like property
                                taxes and accounting costs—are tax-deductible.
                              </p>
                            </div>
                          </div>
                        </Col>
                        <Col sm={12} md={4}>
                          <div className="feature">
                            <div className="feature-content">
                              <h4>Freedom to Make Modifications</h4>
                              <p>
                                Homeownership enables you to live life under
                                your own rules.
                              </p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="lender-apply">
                      <Row>
                        <Col sm={12}>
                          <h2 className="mb30">
                            Why You Should Consider Homeownership
                          </h2>
                          <ul className="listnone bullet">
                            <li>
                              <FiCheckCircle className="bullet-check-circle-default" />
                              The earlier you start homeownership, the earlier
                              you can start building equity. You will be making
                              monthly payments regardless of owning or renting a
                              home, so why give your money to someone else when
                              you can invest the same into your own home and
                              future? Start building your home equity today by
                              owning a home!
                            </li>
                            <li>
                              <FiCheckCircle className="bullet-check-circle-default" />
                              When market conditions are right, you have the
                              choice to sell your property and move up to a
                              bigger place.
                            </li>
                            <li>
                              <FiCheckCircle className="bullet-check-circle-default" />
                              Owning a home is the smart choice, because you
                              receive tax benefits and build your credit at the
                              same time.
                            </li>
                            <li>
                              <FiCheckCircle className="bullet-check-circle-default" />
                              You may consider buying a home as an investment
                              property to rent out for additional income.
                            </li>
                            <li>
                              <FiCheckCircle className="bullet-check-circle-default" />
                              While not many people think about it, your home
                              allows you to define your life with more freedom.
                              It&rsquo;s your own place, design it to suit your
                              own personality, renovate it for future needs, add
                              an entertainment room or a guest room for your
                              friends and relatives, build that beautiful garden
                              for your family, and make some new family
                              additions, such as a pet or two. You may not be
                              able to do most of these with a rented home, can
                              you?
                            </li>
                            <li>
                              <FiCheckCircle className="bullet-check-circle-default" />
                              Buying a home early gives you the opportunity to
                              retire early. Have you ever asked yourself, what
                              if you keep renting? All of those rental payments
                              you have made could be put towards your own place.
                              What if you buy your home much later in life? You
                              would be working longer before you can pay off
                              your mortgage.
                            </li>
                          </ul>
                          <p>
                            For a complete list of recently asked questions,
                            please click here:{" "}
                            <Link to="./Faq">
                              Q&amp;A&nbsp;
                              <GoLinkExternal />
                            </Link>
                          </p>
                          <p>
                            Our licensed and experienced mortgage professionals
                            are ready to answer any questions that you may have.{" "}
                            <br />
                            Please call us at{" "}
                            <a href="tel:+17877869378">
                              <span style={{ color: "#FF9900" }}>
                                <MdLocalPhone />
                                &nbsp;787&#8209;SUN&#8209;WEST.
                              </span>
                            </a>
                          </p>
                          <div className="text-center">
                            <a
                              className="btn btn-primary btns apply_btn_color"
                              onClick={() => {
                                props.setModalShow(true);
                              }}
                            >
                              Apply Now
                            </a>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
