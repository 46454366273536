import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import "./Footer.css";
import SubFooter from "../components/SubFooter";

export default function Footer() {
  return (
    <div>
      <SubFooter />
      <footer className="text-center main-footer">
        <div className="text-center">
          <a
            title="Like us on Facebook!"
            href="https://www.facebook.com/SunWestMortgage"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image
              src="./assest/fb_gray.png"
              alt="Facebook Like Icon"
              style={{ height: "35px" }}
            />
          </a>
          &nbsp;
          <a
            title="Follow us on Twitter!"
            href="https://twitter.com/SunWestMortgage"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="./assest/twitter_gray.png"
              alt="Twitter Icon"
              style={{ height: "35px" }}
            />
          </a>
          &nbsp;
          <a
            title="Follow us on LinkedIn!"
            href="https://www.linkedin.com/company/sun-west-mortgage-company/about/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="./assest/linked_in_gray.png"
              alt="LinkedIn Icon"
              style={{ height: "35px" }}
            />
          </a>
          &nbsp;
        </div>
        &nbsp;
        <p>
          &copy;{new Date().getFullYear()}&nbsp;Sun West Mortgage Company, Inc.
          All Rights Reserved <br />
          <strong>
            For licensing information, go to:{" "}
            <a
              href="http://www.nmlsconsumeraccess.org/EntityDetails.aspx/company/3277"
              title="NMLS Consumer Access"
              target="_blank"
              rel="noreferrer"
            >
              <u>www.nmlsconsumeraccess.org</u>
            </a>
            .<br />
            Visit{" "}
            <a
              href="https://www.swmc.com/disclaimer"
              title="Click Here for full list of license information of Sun West Mortgage Company, Inc."
              target="_blank"
              rel="noreferrer"
            >
              <u>www.swmc.com/disclaimer</u>
            </a>{" "}
            for the full list of license information.
          </strong>
          <br></br>
          Please&nbsp;
          <a
            href="https://www.swmc.com/TXdis.php"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="to view texas compliant notice and servicing disclosure"
          >
            <u>Click Here</u>&nbsp;
          </a>
          to view Texas Complaint Notice and Servicing Disclosure.
        </p>
        <p>
          <strong>
            Although Sun West is approved to conduct business in the state of
            New York, this website has not yet been approved by the State of New
            York Department of Financial Services. <br />
            For properties located in the State of New York, this website can
            not be used to upload an application, please visit{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.swmc.com"
            >
              <u>swmc.com</u>
            </a>{" "}
            to upload an application.
          </strong>
        </p>
        <br />
        <Container>
          <Row className="justify-content-md-center">
            <Col lg={4}>
              <img
                id="equalImg"
                alt="EHO logo"
                src="./assest/eho_blue.png"
                title="Equal Housing Opportunity"
                style={{ width: "70px" }}
              />
            </Col>
            {/* <Col lg={4}>
                            <embed src="https://www.checkbca.org/CompanyWidget.aspx?ID=59000209&amp;WidgetType=1" style={{width: "136px"}} />
                        </Col> */}
          </Row>
        </Container>
      </footer>
    </div>
  );
}
