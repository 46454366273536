import React from 'react';
// import { Link } from 'react-router-dom';
import Image from 'react-bootstrap/Image';
import {Helmet} from "react-helmet";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


export default function Error() {
    return (
        <div>
            <Helmet>
                <title>404 Page Not Found</title>
                <meta name="description" content="For over 7 years, Sun West has been part of Puerto Rico's business community and community efforts" />
            </Helmet>
            <Container fluid style={{background:"#d2d2d2"}}>
                <Row>
                    <Col className="text-center p-4">
                        <Image src="./assest/404.png" className="img-fluid" />
                        <h1>Error 404 Not Found</h1>
                        <p>Sorry, an error ocurred. The requested page was not found!</p>
                        {/* <Link></Link> */}
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
