import React from "react";
import { Link } from "react-router-dom";
import { genericHashLink } from "react-router-hash-link";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { FaHome } from "react-icons/fa";
import "../Navbar.css";

import "./SideDrawer.css";

const MyHashLink = (props) => genericHashLink(props, Link);

const SideDrawer = (props) => {
  let drawerClasses = ["side-drawer"];
  if (props.show) {
    drawerClasses = "side-drawer open";
  }

  return (
    <nav className={drawerClasses}>
      <div id="sideDrawerItems" className="sideDrawerItems">
        <Nav.Link as={Link} to="./" onClick={props.click} className="navLink">
          Home <FaHome className="homeIcon" />
        </Nav.Link>
        <Nav.Link as={Link} onClick={props.click} to="./About">
          About Us
        </Nav.Link>
        <NavDropdown title="Loan Programs" id="loanPrograms">
          <NavDropdown.Item
            data-toggle="dropdown"
            className="navLink"
            onClick={props.click}
            as={Link}
            to="./Fha"
          >
            FHA
          </NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item
            to="./Conventional"
            onClick={props.click}
            as={Link}
            className="navLink"
          >
            Conventional
          </NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item
            as={Link}
            className="navLink"
            onClick={props.click}
            to="./Va"
          >
            VA
          </NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item
            className="navLink"
            as={Link}
            onClick={props.click}
            to="./Renovation"
          >
            Home Style Renovation
          </NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item
            className="navLink"
            as={Link}
            onClick={props.click}
            to="./Reverse"
          >
            Reverse Mortgage
          </NavDropdown.Item>
        </NavDropdown>
        <NavDropdown title="Resources" id="resources">
          <NavDropdown.Item
            as={Link}
            to="./Buying"
            onClick={props.click}
            className="navLink"
          >
            Home Buyer&#39;s Guide
          </NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item
            className="navLink"
            onClick={props.click}
            as={Link}
            to="./Refinancing"
          >
            Refinancing Guide
          </NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item
            className="navLink"
            onClick={props.click}
            as={Link}
            to="./Documents"
          >
            Documents Needed
          </NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item
            className="navLink"
            onClick={props.click}
            as={Link}
            to="./Calculators"
          >
            Mortgage Calculators
          </NavDropdown.Item>
        </NavDropdown>
        <Nav.Link
          className="navLink"
          as={Link}
          onClick={props.click}
          to="./Team"
        >
          Team
        </Nav.Link>
        <Nav.Item>
          <MyHashLink
            className="navLink nav-link"
            onClick={props.click}
            smooth
            to="./#contactUsSpan"
          >
            Contact Us
          </MyHashLink>
        </Nav.Item>
        <Nav.Link
          className="navLink"
          as={Link}
          onClick={props.click}
          to="./Faq"
        >
          FAQ
        </Nav.Link>
        <div
          className="navLink textWhite"
          onClick={() => {
            props.setModalShow(true);
          }}
        >
          Apply Now
        </div>
      </div>
    </nav>
  );
};

export default SideDrawer;
