import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link, withRouter } from 'react-router-dom';
import { genericHashLink } from 'react-router-hash-link';
import './SubFooter.css'

const MyHashLink = (props) => genericHashLink(props, Link);

function SubFooter() {
    return (
        <div>
             <section className="text-center box-shadow">
                <Container className="sub-footer p-5">
                    <Row>
                        <Col xl={12} className="bottomLinks">
                            <span>
                                <Link to="./">Home</Link> |
                                <Link to="./disclaimer">Licenses</Link> |
                                <Link to="./Sitemap">Sitemap</Link> |
                                <a title="Privacy Policy" target="_blank" rel="noopener noreferrer" href="https://www.swmc.com/privacy-policy.php">Privacy Policy</a> |
                                <Link to="./Terms">Terms &amp; Conditions</Link> |
                                <MyHashLink smooth to="./#contactUsSpan">Contact Us</MyHashLink>
                            </span>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default withRouter(SubFooter);