import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import StyledHero from '../components/StyledHero'
import RatesImg from '../images/rates.jpeg';
import Banner from '../components/Banner';
import {Helmet} from "react-helmet";




export default function FixedLoans() {

    return (
        <div>
            <Helmet>
                <title>Fixed Mortgage</title>
                <meta name="description" content="Attractive mortgage rates without compromise in service." />
            </Helmet>
            <StyledHero img={RatesImg}>
                <Banner title="Fixed Mortgage">
                </Banner>
            </StyledHero>            
            <Container>
                <div className="wrapper-content bg-white rounded box-shadow">
                    <div className="pinside40 rounded">
                        <div className="product-tabs">
                            <Row>
                                <Col lg={12} className="nopadding">
                                    <div className="tab-content">
                                        <div className="full-width columns" id="10yrfixedcon">
                                            <h2 className="top_content_heading">Conventional 10 Year Fixed</h2>
                                            <strong>Important disclosures, assumptions, and information</strong>
                                            <p>The interest rate shown is with no points for a 10 year fixed, <strong>"no cash-out"</strong> refinance loan and assumes a minimum FICO score of 740 with a maximum <strong>loan-to-value ratio of 80%</strong> on a primary residence. The actual interest rate, APR, and payment may vary based on the specific terms of the loan selected, verification of information, your credit history, the location and type of property, and other factors as determined by Sun West Mortgage Company, Inc. Rates shown is based on a 45-day lock-in period. Not available in all states. Rates are subject to change daily without notice. The borrower may choose to apply for a loan with (1) other fee options in which points and/or fees are charged to obtain a lower interest rate, or (2) higher rate to obtain a credit towards certain eligible closing costs. Payment does not include taxes and insurance premiums. The actual payment amount will be greater. Some state and county maximum loan amount restrictions may apply.</p>
                                        </div>
                                        <br/>
                                        <div className="full-width columns" id="15yrfixedcon">
                                            <h2 className="top_content_heading">Conventional 15 Year Fixed</h2>
                                            <strong>Important disclosures, assumptions, and information</strong>
                                            <p>The interest rate shown is with no points for a 15 year fixed, <strong>"no cash-out"</strong> refinance loan and assumes a minimum FICO score of 740 with a maximum <strong>loan-to-value ratio of 80% </strong>on a primary residence. The actual interest rate, APR, and payment may vary based on the specific terms of the loan selected, verification of information, your credit history, the location and type of property, and other factors as determined by Sun West Mortgage Company, Inc. Rates shown is based on a 45-day lock-in period. Not available in all states. Rates are subject to change daily without notice. The borrower may choose to apply for a loan with (1) other fee options in which points and/or fees are charged to obtain a lower interest rate, or (2) higher rate to obtain a credit towards certain eligible closing costs. Payment does not include taxes and insurance premiums. The actual payment amount will be greater. Some state and county maximum loan amount restrictions may apply.</p>
                                        </div>

                                        <br/>
                                        <div className="full-width columns" id="30yrfixedcon">
                                            <h2 className="top_content_heading">Conventional 30 Year Fixed</h2>
                                            <strong>Important disclosures, assumptions, and information</strong>
                                            <p>The interest rate shown is with no points for a 30 year fixed, <strong>"no cash-out"</strong> refinance loan and assumes a minimum FICO score of 740 with a maximum <strong>loan-to-value ratio of 75% </strong>on a primary residence. The actual interest rate, APR, and payment may vary based on the specific terms of the loan selected, verification of information, your credit history, the location and type of property, and other factors as determined by Sun West Mortgage Company, Inc. Rates shown is based on a 45-day lock-in period. Not available in all states. Rates are subject to change daily without notice. The borrower may choose to apply for a loan with (1) other fee options in which points and/or fees are charged to obtain a lower interest rate, or (2) higher rate to obtain a credit towards certain eligible closing costs. Payment does not include taxes and insurance premiums. The actual payment amount will be greater. Some state and county maximum loan amount restrictions may apply.</p>
                                        </div>

                                        <br/>
                                        <div className="full-width columns" id="15yrfixedfha">
                                            <h2 className="top_content_heading">FHA 15 Year Fixed</h2>
                                            <strong>Important disclosures, assumptions, and information</strong>
                                            <p>The interest rate shown is with no points for a 15 year fixed and assumes a minimum FICO score of 680 with a maximum <strong>loan-to-value ratio of 96.5%</strong> on a purchase of an owner occupied single family residence. The actual interest rate, APR, and payment may vary based on the specific terms of the loan selected, verification of information, your credit history, the location and type of property, and other factors as determined by Sun West Mortgage Company, Inc. Rates shown is based on a 45-day lock-in period. Not available in all states. Rates are subject to change daily without notice. The borrower may choose to apply for a loan with (1) other fee options in which points and/or fees are charged to obtain a lower interest rate, or (2) higher rate to obtain a credit towards certain eligible closing costs.</p>
                                        </div>

                                        <br/>
                                        <div className="full-width columns" id="30yrfixedfha">
                                            <h2 className="top_content_heading">FHA 30 Year Fixed</h2>
                                            <strong>Important disclosures, assumptions, and information</strong>
                                            <p>The interest rate shown is with no points for a 30 year fixed and assumes a minimum FICO score of 680 with a maximum <strong>loan-to-value ratio of 96.5%</strong> on a purchase of primary residence. The actual interest rate, APR, and payment may vary based on the specific terms of the loan selected, verification of information, your credit history, the location and type of property, and other factors as determined by Sun West Mortgage Company, Inc. Rates shown is based on a 45-day lock-in period. Not available in all states. Rates are subject to change daily without notice. The borrower may choose to apply for a loan with (1) other fee options in which points and/or fees are charged to obtain a lower interest rate, or (2) higher rate to obtain a credit towards certain eligible closing costs. Payment does not include taxes and insurance premiums. The actual payment amount will be greater. Some state and county maximum loan amount restrictions may apply.</p>
                                        </div>

                                        <br/>
                                        <div className="full-width columns" id="15yrfixedva">
                                            <h2 className="top_content_heading">VA 15 Year Fixed</h2>
                                            <strong>Important disclosures, assumptions, and information</strong>
                                            <p>The interest rate shown is with no points for a 15 year fixed and assumes a minimum FICO score of 680 with a maximum <strong>loan-to-value ratio of 100%</strong> on a purchase of an owner occupied single family residence. The actual interest rate, APR, and payment may vary based on the specific terms of the loan selected, verification of information, your credit history, the location and type of property, and other factors as determined by Sun West Mortgage Company, Inc. Rates shown is based on a 45-day lock-in period. Not available in all states. Rates are subject to change daily without notice. The borrower may choose to apply for a loan with (1) other fee options in which points and/or fees are charged to obtain a lower interest rate, or (2) higher rate to obtain a credit towards certain eligible closing costs.</p>
                                        </div>
                                        <br/>

                                        <div className="full-width columns" id="30yrfixedva">     <h2 className="top_content_heading">VA 30 Year Fixed</h2>
                                            <strong>Important disclosures, assumptions, and information</strong>
                                            <p>The interest rate shown is with no points for a 30 year fixed and assumes a minimum FICO score of 680 with a maximum <strong>loan-to-value ratio of 100%</strong> on a purchase of primary residence. The actual interest rate, APR, and payment may vary based on the specific terms of the loan selected, verification of information, your credit history, the location and type of property, and other factors as determined by Sun West Mortgage Company, Inc. Rates shown is based on a 45-day lock-in period. Not available in all states. Rates are subject to change daily without notice. The borrower may choose to apply for a loan with (1) other fee options in which points and/or fees are charged to obtain a lower interest rate, or (2) higher rate to obtain a credit towards certain eligible closing costs. Payment does not include taxes and insurance premiums. The actual payment amount will be greater. Some state and county maximum loan amount restrictions may apply.</p>
                                        </div>
                                        <br/>

                                        <div className="full-width columns" id="30yrfixedusda">
                                            <h2 className="top_content_heading">USDA 30 Year Fixed</h2>
                                            <strong>Important disclosures, assumptions, and information</strong>
    
                                            <p>The interest rate shown is with no points for a 30 year fixed and assumes a minimum FICO score of 680 with a maximum <strong>loan-to-value ratio of 100%</strong> on purchase of a primary residence. The actual interest rate, APR, and payment may vary based on the specific terms of the loan selected, verification of information, your credit history, the location and type of property, and other factors as determined by Sun West Mortgage Company, Inc. Rates shown is based on a 45-day lock-in period. Not available in all states. Rates are subject to change daily without notice. The borrower may choose to apply for a loan with (1) other fee options in which points and/or fees are charged to obtain a lower interest rate, or (2) higher rate to obtain a credit towards certain eligible closing costs. Payment does not include taxes and insurance premiums. The actual payment amount will be greater. Some state and county maximum loan amount restrictions may apply.</p>
                                        </div>  
                                    </div>                                 
                                </Col>
                            </Row>
                        </div>
                     </div>
                </div>
            </Container>              
        </div>
    )
}
