import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import StyledHero from "../components/StyledHero";
import ConvImg from "../images/conventional.jpg";
import Banner from "../components/Banner";
import { FiCheckCircle } from "react-icons/fi";
import { GoLinkExternal } from "react-icons/go";
import { MdLocalPhone } from "react-icons/md";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function Conventional(props) {
  return (
    <div id="conventionalWrapper">
      <Helmet>
        <title>Conventional Loans</title>
        <meta
          name="description"
          content="A conventional mortgage or conventional loan is any type of homebuyer's loan that is not offered or secured by a government entity, but rather available through or guaranteed by a private lender."
        />
      </Helmet>
      <StyledHero img={ConvImg}>
        <Banner title="Conventional Mortgage"></Banner>
      </StyledHero>
      <Container>
        <div className="wrapper-content bg-white rounded box-shadow">
          <div className="pinside40 rounded">
            <div className="product-tabs">
              <Row>
                <Col lg={12} className="nopadding">
                  <div className="tab-content">
                    <div
                      role="tabpanel"
                      className="tab-pane active"
                      id="overview"
                    >
                      <h2>About Conventional Mortgage</h2>
                      <p>
                        A conventional mortgage or conventional loan is any type
                        of homebuyer's loan that is not offered or secured by a
                        government entity, like the Federal Housing
                        Administration (FHA), the U.S. Department of Veterans
                        Affairs (VA) or the USDA Rural Housing Service, but
                        rather available through or guaranteed by a private
                        lender (banks, credit unions, mortgage companies) or the
                        two government-sponsored enterprises, the Federal
                        National Mortgage Association (Fannie Mae) and the
                        Federal Home Loan Mortgage Corporation (Freddie Mac).
                      </p>
                      <h2>Conventional Conforming</h2>
                      <p>
                        A Conventional "Conforming" loan is a loan whose terms and conditions meet the requirements of Fannie Mae and Freddie Mac. These include a limit on the maximum amount to be financed, set annually by the Federal Housing Finance Agency (FHFHA). Currently in Puerto Rico, a loan cannot exceed $726,200 for 1 unit. The Conventional mortgage is the best option for buyers who want the property for investment or as a second home; or for those who want to buy a house with a price greater than $700,000.
                      </p>
                      <h3>
                        Conventional Conforming Mortgage Benefits and Highlights
                      </h3>
                      <ul className="listnone bullet">
                        <li>
                          <FiCheckCircle className="bullet-check-circle-default" />
                          Must have a credit score of at least 620 and, preferably, well over 700. The higher the score, the lower the interest rate on the loan, with the best terms being reserved for those over 740.
                        </li>
                        <li>
                          <FiCheckCircle className="bullet-check-circle-default" />
                          DTI allowed according to AUS, i.e, maximum 50%.
                        </li>
                        <li>
                          <FiCheckCircle className="bullet-check-circle-default" />
                          Mortgage insurance is not required if the down payment is 20% or more.
                        </li>
                      </ul>
                      <h2>Conventional Non-Conforming</h2>
                      <p>
                        Non-conforming loans are mortgages that do not meet the
                        loan limits discussed above, as well as other standards
                        related to your credit-worthiness, financial standing,
                        documentation status, among others. Fannie Mae cannot
                        purchase non-conforming loans.
                      </p>

                      <p>
                        For a list of documents required upon closing, please
                        click here:
                        <Link to="./Documents">
                          {" "}
                          CHECKLIST&nbsp;
                          <GoLinkExternal />
                        </Link>
                      </p>
                      <p>
                        Our licensed and experienced mortgage professionals are
                        ready to answer any questions that you may have. <br />
                        Please call us at{" "}
                        <a href="tel:+17877869378">
                          <MdLocalPhone />
                          &nbsp;787&#8209;SUN&#8209;WEST.
                        </a>
                      </p>
                      <div className="text-center">
                        <a
                          className="btn btn-primary btns apply_btn_color"
                          onClick={() => {
                            props.setModalShow(true);
                          }}
                        >
                          Apply Now
                        </a>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
