import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import StyledHero from "../components/StyledHero";
import RefiImg from "../images/refinancing.jpg";
import Banner from "../components/Banner";
import { FiCheckCircle } from "react-icons/fi";
import { GoLinkExternal } from "react-icons/go";
import { MdLocalPhone } from "react-icons/md";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function Refinancing(props) {
  return (
    <div id="refiWrapper">
      <Helmet>
        <title>Refinancing</title>
        <meta
          name="description"
          content="Sun West Mortgage Company, Inc., offers a full line of refinancing options."
        />
      </Helmet>
      <StyledHero img={RefiImg}>
        <Banner title="Refinancing Your Property"></Banner>
      </StyledHero>
      <Container>
        <div className="wrapper-content bg-white rounded box-shadow">
          <div className="pinside40 rounded">
            <div className="product-tabs">
              <Row>
                <Col lg={12}>
                  <div className="lender-details">
                    <div className="lender-head-sections">
                      <Row>
                        <Col sm={12} md={3}>
                          <div className="lender-img">
                            <Image
                              src="./assest/Logo-small.png"
                              className="img-fluid"
                              alt="Sun West Mortgage Company, Inc. logo"
                            />
                          </div>
                        </Col>
                        <Col className="mb-3" sm={12} md={7}>
                          <h2 className="lender-name">
                            Refinancing Your Current Mortgage
                          </h2>
                          <p>
                            Sun West Mortgage Company, Inc., offers a full line
                            of refinancing options. Our mortgage experts can
                            discuss your financial objectives, and recommend the
                            best option. Whether you need to use your home
                            equity for a child&rsquo;s college education,
                            acquiring a new car, or simply for home improvement
                            projects, there are options.
                          </p>
                        </Col>
                        <Col sm={12} className="text-center">
                          <a
                            // href="https://www.swmc.com/ApplyNow/?extLeadSource=LOWRATESUS"
                            // target="_blank"
                            // rel="noopener noreferrer"
                            className="btn btn-sm rounded box-shadow btn-primary apply_btn_color"
                            onClick={() => {
                              props.setModalShow(true);
                            }}
                          >
                            Apply Now
                          </a>
                        </Col>
                      </Row>
                    </div>
                    <div className="mb30">
                      <div className="bg-sun pinside20 borderTop">
                        <h3 className="mb0 text-white text-center">
                          Conventional (One of Our Program Options)
                        </h3>
                      </div>
                      <div className="outline pinside20 box-shadow borderBottom">
                        <Row>
                          <Col sm={6} md={4} lg={4}>
                            <div className="text-center">
                              <h3 className="rate">5.000% Rate</h3>
                              <h3 className="rate">5.240% APR</h3>
                              <small>10-Year Fixed</small>
                            </div>
                          </Col>
                          <Col sm={6} md={4} lg={4}>
                            <div className="text-center">
                              <h3 className="fees">4.875% Rates</h3>
                              <h3 className="fees">5.115% APR</h3>
                              <small>15-Year Fixed</small>
                            </div>
                          </Col>
                          <Col sm={6} md={4} lg={4}>
                            <div className="text-center">
                              <h3 className="compare-rate">5.125% Rates</h3>
                              <h3 className="compare-rate">5.365% APR</h3>
                              <small>30-Year Fixed</small>
                            </div>
                          </Col>
                        {/* "See All Programs" button is commented as /rates page was showing old rates */}

                          {/* <Col
                            className="text-center p-0"
                            sm={12}
                            md={12}
                            lg={3}
                          >
                            <div className="compare-action">
                              {" "}
                              <a
                                href="./rates"
                                className="btn btn-primary btn-sm rounded box-shadow p-3"
                              >
                                See All Programs
                              </a>{" "}
                            </div>
                          </Col> */}
                        </Row>
                      </div>
                    </div>

                    <div className="lender-apply">
                      <Row>
                        <Col sm={12}>
                          <h2 className="mb30">
                            Why You Should Consider Refinancing Your Mortgage
                          </h2>
                          <ul className="listnone bullet">
                            <li>
                              <FiCheckCircle className="bullet-check-circle-default" />
                              By refinancing, you have the ability to access the
                              equity of your home through a Cash-Out Refinance
                              option.
                            </li>
                            <li>
                              <FiCheckCircle className="bullet-check-circle-default" />
                              Refinance to obtain a new, lower interest rate
                              through a Rate &amp; Term Refinance option. The
                              current interest rate may have decreased since you
                              previously financed your mortgage.
                            </li>
                            <li>
                              <FiCheckCircle className="bullet-check-circle-default" />
                              Refinancing your mortgage to a lower interest rate
                              will lower your monthly payments.
                            </li>
                            <li>
                              <FiCheckCircle className="bullet-check-circle-default" />
                              Refinancing your mortgage with the objective of
                              debt consolidation will provide you with extra
                              short-term cash to help pay off your current
                              credit card bills. Compared to a mortgage, credit
                              cards usually charge much higher interest rates.
                            </li>
                          </ul>
                          <p>
                            For a complete list of recently asked questions,
                            please click here:{" "}
                            <Link to="./Faq">
                              Q&amp;A&nbsp;
                              <GoLinkExternal />
                            </Link>
                          </p>
                          <p>
                            Our licensed and experienced mortgage professionals
                            are ready to answer any questions that you may have.{" "}
                            <br />
                            Please call us at{" "}
                            <a href="tel:+17877869378">
                              <MdLocalPhone />
                              &nbsp;787&#8209;SUN&#8209;WEST.
                            </a>
                          </p>
                          <div className="text-center">
                            <a
                              //   href="https://www.swmc.com/ApplyNow/?extLeadSource=LOWRATESUS"
                              //   target="_blank"
                              //   rel="noopener noreferrer"
                              className="btn btn-primary btns apply_btn_color"
                              onClick={() => {
                                props.setModalShow(true);
                              }}
                            >
                              Apply Now
                            </a>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
